import { APP_DEF_ID, DASHBOARD_PAGES_HELP_ID } from '../constants/helpIds';
import { handleSetAsDynamicPage } from '../pages-utils/dynamic';
import { panelDefinitions } from '../panel-definitions';
import {
  createPage,
  ensureAllPagesHaveControllers,
  removePage,
} from '../pages-utils/editor';
import { reportError } from '../pages-utils/errors';
import { ControllerType, EditorAppContext, EditorPlatformApp } from './types';
import { eventNames } from '../constants/eventNames';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';

export function createEditorPlatformApp(): EditorPlatformApp {
  let appContext: EditorAppContext;
  let flowAPI: EditorScriptFlowAPI;
  let firstPage: boolean;
  let appDefinitionId: string;

  return {
    async editorReady(editorSDK, _appDefinitionId, options, _flowAPI) {
      appDefinitionId = _appDefinitionId;
      const { firstInstall } = options;
      firstPage = firstInstall;
      flowAPI = _flowAPI;

      const { experiments, fedops, errorMonitor, environment, translations } =
        flowAPI;
      const { isEditorX } = environment;

      appContext = {
        flowAPI,
        translate: translations.t.bind(translations),
        editorSDK,
        appDefinitionId,
        fedops,
        experiments,
        isEditorX: environment.isEditorX,
        reportError(error) {
          reportError(errorMonitor, error, {
            firstInstall,
            isEditorX,
          });
        },
        instance: await editorSDK.info.getAppInstance(appDefinitionId),
      };

      const adminPages = await editorSDK.pages.getApplicationPages(
        appDefinitionId,
      );

      if (!firstInstall) {
        ensureAllPagesHaveControllers(appContext, adminPages);
        return;
      }

      if (adminPages && adminPages.length === 0) {
        return editorSDK.editor.openModalPanel(
          appDefinitionId,
          panelDefinitions.addPagePanel(),
        );
      }

      return createPage({
        editorSDK,
        httpClient: flowAPI.httpClient,
        token: appDefinitionId,
        isEditorX,
        translate: appContext.translate,
      });
    },

    async getAppManifest({ appManifestBuilder }) {
      return appManifestBuilder
        .configureController(
          ControllerType.SingleAdminPage,
          (controllerBuilder) => {
            controllerBuilder.set({
              visibility: 'NEVER',
            });
          },
        )
        .configurePages((pagesBuilder) => {
          pagesBuilder // page builder is not fot a specific widget
            .configureState('custom-page-state', (pagesStateBuilder) => {
              pagesStateBuilder.addAction({
                advanced: false,
              });
            })
            .addAction(
              {
                event: eventNames.deletePage,
                type: 'page_delete',
              },
              {
                type: 'page_rename',
              },
              {
                title: appContext.translate('page_actions.set_as_dynamic_page'),
                icon: 'page_dynamic_icon',
                event: eventNames.setAsADynamicPage,
              },
            )
            .addSettingsTab(
              {
                title: appContext.translate('page_settings.page_info'),
                type: 'page_info',
                url: panelDefinitions.pageInfoPanel().url,
              },
              {
                title: appContext.translate('page_settings.page_permissions'),
                type: 'permissions',
                url: panelDefinitions.permissionsPanel().url,
              },
            );
        })
        .configurePagesTab((pagesTabBuilder) => {
          const {
            editorSDK,
            flowAPI: { httpClient },
            appDefinitionId: appContextAppDefinitionId,
            isEditorX,
            experiments,
          } = appContext;
          pagesTabBuilder
            .set({
              displayName: appContext.translate('dashboard-pages'),
              helpId: DASHBOARD_PAGES_HELP_ID,
            })
            .addAction({
              title: experiments.enabled('specs.wosbm.AdminPagesAddPageNewKey')
                ? appContext.translate(
                    'page_settings.add_dashboard_page_button',
                  )
                : appContext.translate('dashboard-pages'),
              icon: 'addPagePagesPanel',
              onClick: () => {
                createPage({
                  editorSDK,
                  token: appContextAppDefinitionId,
                  httpClient,
                  isEditorX,
                  translate: appContext.translate,
                });
              },
            })
            .setGrouping('routers');
        })
        .build();
    },
    async handleAction() {},

    async onEvent(event, editorSDK) {
      switch (event.eventType) {
        case eventNames.dataSectionAppClicked:
          if (!firstPage) {
            return createPage({
              editorSDK,
              httpClient: flowAPI.httpClient,
              token: APP_DEF_ID,
              isEditorX: appContext.isEditorX,
              translate: appContext.translate,
            });
          }
          firstPage = false;
          return;
        case eventNames.deletePage:
          return removePage(editorSDK, event.eventPayload.pageRef);
        case eventNames.setAsADynamicPage:
          return handleSetAsDynamicPage(
            event.eventPayload.pageRef,
            editorSDK,
            appContext.appDefinitionId,
            flowAPI.httpClient,
          );
      }
    },
  };
}
